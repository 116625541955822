<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Folios" :breadcrumbs="breadcrumbs" :hiddenTab="true" :dense="true">
      <template v-slot:main>
        <v-btn v-if="$helpers.hasPermission(['add_caf'])" color="primary" @click="dialogCreateFolio=true"><v-icon left>mdi-file-document-plus</v-icon>Cargar folios</v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <PermissionDenied v-if="!$helpers.hasSomePermission(['view_caf'])" />
    <v-col cols="12" class="px-0" v-else>
      <v-skeleton-loader class="elevation-0" type="table" v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!cafsList.length" title="No existen folios para mostrar" type="documents" id="folios" :isFree="true" customClass="mt-n8">
          <!-- <template v-slot:actions>
            <v-btn @click="activeFilter = true" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
            <v-btn class="ml-2" @click="setSort('-date')" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
          </template> -->
        </v-empty-state>
        <template v-else>
          <v-data-table class="table-condense d-none" :headers="currentHeader" :items="cafsList" hide-default-header disable-sort mobile-breakpoint hide-default-footer @click:row="(item) => $router.push({ name: 'FoliosRetrieve', params: { id: item.document_type } }).catch(() => {})" style="cursor:pointer">
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders :headers="headers" />
            </template>

            <template v-slot:[`item.document_type`]="{item}">
              <span v-if="item.document_type" style="white-space: normal !important">{{item.document_type | nameDocumentsType}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.total`]="{item}">
              <span class="body-1 font-weight-bold" v-if="item.total">{{item.total}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.used`]="{item}">
              <span class="text--secondary font-weight-medium" v-if="item.used">{{item.used}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.available`]="{item}">
              <span class="text--secondary font-weight-medium" v-if="item.available">{{item.available}}</span>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>

            <template v-slot:[`item.available_to_give`]="{item}">
              <v-icon v-if="!item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.available_to`]="{item}">
              <v-icon v-if="item.available_to_give" color="success">mdi-check-circle-outline</v-icon>
              <v-icon v-else color="secondary">mdi-minus</v-icon>
            </template>

            <template v-slot:[`item.status`]="{item}">
              <v-chip v-if="item.status === 'normal'" color="green lighten-1" dark small>Normal</v-chip>
              <v-chip v-else-if="item.status === 'alert'" color="orange lighten-1" dark small>Alerta</v-chip>
              <v-chip v-else-if="item.status === 'risk'" color="red lighten-1" dark small>Crítico</v-chip>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
          </v-data-table>
          <v-row>
            <v-col v-for="(caf, index) in cafsList" :key="`${caf.id}-${index}`" cols="4" md="3" :lg="$vuetify.breakpoint.width > 1200 ? 3 : 2">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat :to="{name: 'FoliosRetrieve', params: {id: caf.document_type, ...$route.params}}">
                  <v-icon class="mb-2" color="primary" size="16">mdi-file-document</v-icon>
                  <span class="d-block mt-2">
                    <v-sheet class="transparent" height="25">
                      <span v-if="caf.document_type.length" class="secondary--text font-weight-semibold subtitle-2 d-inline-block text-truncate" style="max-width: 99%;">{{caf.document_type | nameDocumentsType}}</span>
                      <span class="d-block mt-n1 body-2 text--disabled" v-else>Sin especificar</span>
                    </v-sheet>
                  </span>
                  <span v-if="caf.total" class="mt-2 d-block body-1 fontDraw--text d-inline-block text-truncate" style="max-width: 99%;">Cantidad cargada: {{caf.total | number}}</span>
                  <span class="d-block mt-n1 body-2 text--disabled" v-else>Sin especificar</span>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </template>
    </v-col>

    <!-- dialog create folio -->
    <v-dialog v-model="dialogCreateFolio" width="775" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Cargar folios</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreateFolio=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pt-1 pb-8 px-0">
          <v-row class="px-5" align="center" no-gutters>
            <v-col cols="3" class="mt-7">
              <img :src="require(`@/assets/backgrounds/modal-folios-upload.svg`)" :width="168" />
            </v-col>
            <v-col class="pl-4">
              <div class="mb-16 mt-5">
                <v-file-input class="ml-n1" v-model="newCaf.file" ref="newCaf" type="file" :error="$v.newCaf.file.$error" accept=".xml" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                <span class="body-1 d-block body-1 fontBody--text">Selecciona o arrastra un archivo de folios obtenidos desde sii.cl</span>
                <span class="d-block mt-3 body-1 fontBody--text font-weight-bold">El uso exclusivo de los folios cargados dependerá de la selección que ud indique</span>
                <v-radio-group class="ma-0 mt-2" v-model="newCaf.available_to_give" mandatory row absolute style="position:absolute" dense hide-details>
                  <v-radio label="Folios exclusivos para integración Agente/Webservice" value="true"></v-radio>
                  <v-radio class="mt-1" label="Folios exclusivos para emitir documentos desde plataforma" value="false"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <!-- <v-divider class="mt-10" />
          <v-row class="px-4 py-4" no-gutters>
            <v-col>
              <v-list-item>
                <v-list-item-title class="subtitle-2 text--secondary font-weight-bold">Artículos de ayuda</v-list-item-title>
              </v-list-item>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-title>
                    <v-btn :href="`${$DOCS_BASE}${guides[0]}`" target="_blank" text color="primary" :ripple="false">Cómo solicitar timbraje de folios en SII<v-icon right small>mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn :href="`${$DOCS_BASE}${guides[1]}`" target="_blank" text color="primary" :ripple="false">Cómo subir folios<v-icon right small>mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>
                    <v-btn :href="`${$DOCS_BASE}${guides[2]}`" target="_blank" text color="primary" :ripple="false">Cómo anular folios vencidos<v-icon right small>mdi-open-in-new</v-icon></v-btn>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row> -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="text-right py-2 px-5">
          <v-spacer />
          <v-btn @click="dialogCreateFolio=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="createCaf" :loading="$store.state.irs.createLoader" color="primary">Cargar folios</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog create folio -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import MiniHeader from '@/components/commons/MiniHeader'
import { required } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import documentTypesList from '@/collections/documentTypes'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    VEmptyState,
    PermissionDenied,
    MiniHeader,
    VTableHeaders: () => import('@/components/VTable/VTableHeaders')
  },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    scroll: 0,
    loading: false,
    dialogCreateFolio: false,
    documentTypes: documentTypesList.filter(d => d.country === 'CL'),
    guides: [
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#solicitud-de-timbraje-de-folios',
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#folios',
      '/guide/settings/documents/proveedores-de-facturacion/facturacion-incorporada#anulacion-de-folios-vencidos'
    ],
    newCaf: {
      file: null,
      available_to_give: false
    },
    headers: [
      { text: 'Tipo de documento', value: 'document_type', sortable: true },
      { text: 'Cantidad cargada', value: 'total', align: 'end', sortable: true }
      // { text: 'Usados', value: 'used', align: 'end', sortable: true },
      // { text: 'Disponibles', value: 'available', align: 'end', sortable: true },
      // { text: 'Estado', value: 'status', align: 'end' }
    ],
    columnSupport: [
      { text: 'WS/Agente', value: 'available_to_give', align: 'center' },
      { text: 'Plataforma', value: 'available_to', align: 'center' }
    ],
    statusFolios: [
       {
        id: "normal",
        name: 'Normal',
        detail: '',
        color: 'var(--axt-green-light)',
        icon: 'mdi-check',
        isLight: true
      },
      {
        id: "risk",
        name: 'Riesgo operacional',
        detail: '',
        color: 'var(--axt-red-darken)',
        icon: 'mdi-close',
        isLight: true
      }
    ],
    env: process.env?.VUE_APP_MODE ?? 'ax',
    breadcrumbs: {
      main: 'Folios',
      children: [
        {
          text: 'Disponibilidad'
        }
      ]
    }
  }),
  computed: {
    ...mapState({
      cafsList: state => state.irs.reportList,
      count: state => state.irs.reportCount
    }),
    currentHeader () {
      if (this.$store.state.auth.userAccount.is_staff) {
        return [...this.headers, ...this.columnSupport]
      }
      return this.headers
    }
  },
  created () {
    this.$store.commit('base/SET_QUERY', {})
    this.getList()
  },
  methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      if (this.$helpers.hasPermission(['view_caf'])) {
        this.loading = true
        this.$store.dispatch('irs/LIST', {
          resource: 'irs/sii/cafs/report'
          // query: {
          //   document_type: 'CL61'
          //   // page_size: 100
          // }
        })
        // .then(() => {
        // })
        .finally(() => {
          this.loading = false
        })
      }
    },
    createCaf () {
      this.$v.newCaf.$touch()
      if (this.$v.newCaf.$invalid) {
        return false
      }
      const payload = new FormData()
      payload.append('file', this.newCaf.file, this.newCaf.file.name)
      payload.append('available_to_give', !(this.newCaf.available_to_give === 'true'))

      this.$store.dispatch('irs/CREATE', {
        resource: 'irs/sii/cafs',
        payload: payload,
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(() => {
        this.$dialog.message.info('Folio guardado correctamente')
        this.getList()
        this.dialogCreateFolio = false
        this.newCaf.file = null
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      // .finally(() => {
      //   this.dialogCreateFolio = false
      // })
    }
  },
  validations: {
    newCaf: {
      file: {
        required
      }
    }
  }
}
</script>